/* eslint-disable no-template-curly-in-string */
const messages = {
  'error.internal': '系統錯誤！',
  'error.required': '此欄位為必填欄',
  'error.requiredField': '{name}為必填欄',
  'error.min': '最小值需為{val}',
  'error.minLength': '最少字符需為{val}',
  'error.maxLength': '最多字符為{val}',
  'error.invalidAddress': '街道、電梯為必填欄位',
  'signup.error.passNotMatch': '輸入密碼不一致',
  'token.expired': '請重新登錄！',
  'token.invalid': '請重新登錄！',
  'token.accountNotActivated': '帳戶尚未完成Email驗證',
  'user.invalidCredential': '帳戶與密碼不符！',
  'user.notFound': '帳戶與密碼不符！',
  'user.resetToken.invalid': '密碼重設有誤，請重新申請密碼重設',
  'user.exists': '用戶已存在！',
  'inventory.insufficientBalance': '商品已售完',
  'sell.orderAmount.invalid': '訂單金額有誤',
  'sell.discountAmount.invalid': '折扣金額有誤',
  'sell.shippingAmount.invalid': '運費金額有誤',
  'sell.billedAmount.invalid': '支付金額有誤',
  'refund.productShipped': '訂單已出貨',
  'discount.codeNotFound': '查無此優惠碼',
  'discount.comboNotAllowed': '輸入之優惠碼無法疊加使用',
  'discount.invalidStartDate': '優惠活動尚未開始',
  'discount.invalidEndDate': '優惠活動已結束',
  'discount.invalidProduct': '訂單商品不符合優惠活動商拼',
  'discount.invalidMinAmount': '訂單商品金額未達優惠碼最低所需消費金額',
  'discount.invalidMinQuantity': '訂單商品不符合優惠活動商拼',
  'discount.invalidMaxUserQuantity': '用戶購買數量已達優惠活動上限',
  'discount.insuffientQuantity': '優惠碼已達使用上限',
  'btn.submit': '提交',
  'btn.confirm': '確認',
  'btn.approve': '核准',
  'btn.complete': '完成',
  'btn.reject': '駁回',
  'btn.save': '儲存',
  'btn.back': '返回',
  'btn.cancel': '取消',
  'btn.preview': '預覽',
  'btn.close': '關閉',
  'btn.add': '新增',
  'btn.edit': '修改',
  'btn.delete': '刪除',
  'btn.more': '更多',
  'btn.import': '匯入',
  'btn.export': '匯出',
  'btn.download': '下載',
  'btn.print': '列印',
  'btn.search': '搜尋',
  'btn.openSync': '開啟同步',
  'btn.closeSync': '關閉同步',
  'btn.sync': '重新同步',
  'btn.copy': '複製',
  'btn.locked': '已鎖定',
  'btn.unlocked': '未鎖定',
  'btn.addToCart': '加入購物車',
  'btn.checkoutCart': '立即結帳',
  'btn.checkout': '結帳',
  'btn.next': '下一步',
  'btn.send': '送出',
  'btn.apply': '使用',
  'message.save.success': '儲存成功！',
  'message.delete.success': '刪除成功！',
  'message.delete': '確認刪除{name}?',
  'message.approve': '確認核准{name}?',
  'message.complete': '確認完成{name}?',
  'message.empty': '[無資料]',
  'placeholder.textfield': '請輸入{label}',
  'title.delete': '刪除{name}',
  'title.approve': '核准{name}',
  'title.complete': '完成{name}',
  'app.login': '登錄',
  'app.logout': '登出',
  'app.signup': '註冊',
  'app.home': '首頁',
  'nav.product': '商品分類',
  'nav.activity': '最新活動',
  'nav.rank': '熱銷排行榜',
  'nav.about': '認識亮碟',
  'nav.discount': '折價券專區',
  'nav.order': '訂單查詢',
  'nav.orderDetail': '訂單明細',
  'nav.member': '會員專區',
  'nav.account': '會員基本資料',
  'nav.login': '會員登入/註冊',
  'nav.logout': '會員登出',
  'nav.search': '搜尋',
  'nav.cart.title': '最新加入項目',
  'field.city': '請選擇縣市',
  'field.district': '請選擇地區',
  'field.street': '請輸入地址',
  'status.ACTIVE': '已完成',
  'status.INACTIVE': '已刪除',
  'status.PENDING': '待處理',
  'login.title': '會員登入/註冊',
  'login.title.forgetPassword': '找回密碼',
  'login.title.resetPassword': '重設密碼',
  'login.field.username': 'EMAIL',
  'login.field.password': '密碼',
  'login.field.password2': '確認密碼',
  'login.btn.login': '登錄',
  'login.btn.signup': '註冊',
  'login.btn.forgetPassword': '忘記密碼？',
  'login.success': '成功登錄',
  'login.message.or': '或',
  'login.message.forgetPassword': '請查看信箱完成密碼重設',
  'login.message.resetPassword': '成功修改登錄密碼',
  'logout.success': '成功登出',
  'activate.title': '驗證帳號',
  'activate.success': '完成電子信箱認證',
  'signup.success': '註冊成功，請查看信箱完成認證',
  'sidebar.category': '商品分類',
  'sidebar.order': '訂單查詢',
  'sidebar.account': '會員專區',
  'category.all': '所有商品',
  'product.quantity': '數量',
  'product.ship': '付款與運送',
  'product.freeShippingRule': '滿NT${amount}免運',
  'product.shippingRule':
    '{provider}，每筆NT${shippingFee}，滿NT${freeAmount}(含以上)免運費',
  'product.summary': '商品特色',
  'product.detail': '詳細說明',
  'product.spec': '商品規格',
  'product.related': '相關推薦',
  'product.notFound': 'loading...',
  'content.detail': '詳全文',
  'checkout.step.product': '確認購物車',
  'checkout.step.shipping': '付款與運送方式',
  'checkout.step.buyer': '資料填寫',
  'cart.cvsLookup': '選取超商地址',
  'cart.productCount': '共{count}項商品',
  'cart.subTotal': '商品金額小計',
  'cart.discountAmount': '折扣金額',
  'cart.shippingAmount': '運費',
  'cart.productTotal': '小計：',
  'cart.excludeShipping': '(不含運費)',
  'cart.discountTitle': '優惠券 ＆ 優惠碼',
  'cart.enterDiscount': '選擇或輸入優惠券與優惠碼',
  'cart.noDiscounts': '暫無可使用的優惠券與優惠碼',
  'cart.placeholder.discountCode': '輸入優惠券代碼或優惠碼',
  'cart.paymentMethod': '付款方式',
  'cart.payment.creditCard': '信用卡一次付清',
  'cart.payment.jkopay': '街口支付',
  'cart.payment.cash': '貨到付款',
  'cart.shippingMethod': '運送方式',
  'cart.shipping.d2d': '宅配',
  'cart.shipping.cvs': '超取',
  'cart.shipping.FAMI': '全家超取',
  'cart.shipping.UNIMART': '7-11超取',
  'cart.shipping.HILIFE': '萊爾富超取',
  'cart.buyer.info': '收件人資訊',
  'cart.buyer.receipt': '統編資訊',
  'cart.field.name': '姓名',
  'cart.field.cellphone': '手機號碼',
  'cart.field.memo': '備註',
  'cart.field.receiptId': '統一編號',
  'cart.field.receiptName': '發票抬頭',
  'cart.field.receiptEmail': '發票收取E-mail',
  'cart.placeholder.name': '請輸入真實姓名',
  'cart.placeholder.cellphone': '輸入有效手機號碼',
  'cart.placeholder.memo': '若有購買上的特別需求，請填寫備註',
  'cart.gift': '贈品',
  'cart.total': '，總計',
  'cart.submitOrder': '完成收件人資訊',
  'order.title': '訂單列表',
  'order.title.cancel': '訂單取消',
  'order.payMethod.CREDIT_CARD': '信用卡一次付清',
  'order.payMethod.JKOPAY': '街口支付',
  'order.payMethod.CASH': '貨到付款',
  'order.shipMethod.D2D': '宅配',
  'order.shipMethod.FAMIC2C': '全家超取',
  'order.shipMethod.UNIMARTC2C': '7-11超取',
  'order.shipMethod.HILIFEC2C': '萊爾富超取',
  'order.shipMethod.FAMI': '全家超取',
  'order.shipMethod.UNIMART': '7-11超取',
  'order.shipMethod.HILIFE': '萊爾富超取',
  'order.status.ACTIVE': '訂單已成立',
  'order.status.INACTIVE': '訂單已取消',
  'order.status.REFUNDED': '訂單已退款',
  'order.status.REFUNDING': '退款申請中',
  'order.status.REFUND_REJECTED': '駁回退款',
  'order.ticketId': '訂單編號：{ticketId}',
  'order.transDate': '訂購日期：{date}',
  'order.orderAmount': '訂購總額：NT$ {amount}（含運費）',
  'order.showProduct': '查看商品',
  'order.closeProduct': '收合商品',
  'order.productDetail': '查看明細',
  'order.cancelOrder': '取消訂單',
  'order.cancelMethod.CREDIT_CARD': '信用卡',
  'order.cancelMethod.CASH': '匯款',
  'order.cancelMethod.JKOPAY': '街口支付',
  'ecpay.paymentNotFound': '訂單尚未付款',
  'account.title': '會員專區',
  'account.pageTitle': '會員基本資料',
  'account.pageSubtitle': '立刻填寫會員資料，就有機會獨享專屬優惠',
  'account.field.username': '帳號',
  'account.field.name': '姓名',
  'account.field.birthday': '生日',
  'account.field.gender': '性別',
  'account.gender.male': '男性',
  'account.gender.female': '女性',
  'account.submit': '填寫完成',
  'password.title': '修改密碼',
  'password.field.password': '原密碼',
  'password.field.newPassword': '新密碼',
}

export default messages
